import { reactive, ref } from 'vue'

export const playerLocked = ref(false)

export function updatePlayerLocked(data) {
  playerLocked.value = data
}

export function showLoginButton(data) {
  if (data) {
    let el = document.querySelector('.vp-customView-button')
    if (el) {
      el.style.display = 'none'
    }
  }
}
